a:visited {
    color: black;
}

.rsc-ts-bubble a,
.rsc-ts-bubble a:visited {
    color: white;
}

.footer-container a,
.footer-container a:visited {
    text-decoration: none;
    color: black;
}

.footer-container {
    margin-top: 20px;
    text-align: center;
    font-size: 10px;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 40px;
}

button.rsc-os-option-element {
    background: #347073;
}

div.rsc-header, div.rsc-container {
    border-radius: 0;
}

div.rsc-header {
    padding-left: 60px;
}

div.rsc-content {
    height: calc(100vh - 180px);
}

div.rsc-container {
    height: 100%;
}

.floatingBackButton {
    position: absolute;
    top: 0;
    z-index: 1050;
    color: yellow;
    margin: 15px;
}


/* NAVBAR */
@media (max-width: 991.98px) {
    .navbar-nav-scroll {
        max-width: 100%;
        height: 2.5rem;
        margin-top: .25rem;
        overflow: hidden;
        font-size: .875rem;
    }

    .navbar-nav-scroll .navbar-nav {
        padding-bottom: 2rem;
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
    }
}

.navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
}

.wrap-normal {
    white-space: normal !important;
}

.pure-button-xxl {
    font-size: 200%;
}

.pure-button-xl {
    font-size: 150%;
}

.font-size-xl {
    font-size: 2.5em;
}
.font-size-l {
    font-size: 1.2em;
}

.font-size-s {
    font-size: 0.8em;
}


.font-weight-bold {
    font-weight: 600;
}
.text-center {
    text-align: center!important;
}

.header {
    -webkit-box-align: center;
    align-items: center;
    background: #B71313;
    color: rgb(255, 255, 255);
    display: flex;
    fill: rgb(255, 255, 255);
    height: 56px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px 10px;
    font-weight: bolder;
    font-size: x-large;
    padding-top:6px;
}

.pure-form-danger {
    color: #4F1321;
}


@media screen and (max-width:48em) {
  .pure-hidden-md {display:none}
}
@media screen and (max-width:35em) {
  .pure-hidden-sm {display:none}
}

.mb-1 { margin-bottom: 0.5em }
.mb-2 { margin-bottom: 1.0em }
.mb-3 { margin-bottom: 1.5em }
.mb-4 { margin-bottom: 2.0em }

.mt-1 { margin-top: 0.5em }
.mt-2 { margin-top: 1.0em }
.mt-3 { margin-top: 1.5em }
.mt-4 { margin-top: 2.0em }

.m-1 { margin: 0.5em }
.m-2 { margin: 1.0em }
.m-3 { margin: 1.5em }
.m-4 { margin: 2.0em }

.mx-1 { margin-left: 0.5em; margin-right: 0.5em }
.mx-2 { margin-left: 1.0em; margin-right: 1.0em }
.mx-3 { margin-left: 1.5em; margin-right: 1.5em }
.mx-4 { margin-left: 2.0em; margin-right: 2.0em }

.pure-button-primary, .pure-button-selected, a.pure-button-primary, a.pure-button-selected {
    background-color: #10171A;
}
